import { useLayoutEffect, useRef, useState } from 'react';

import { debounce } from '@sravni/react-utils';

import type { RenderTextRefType } from '../types';

import { getTextWidth } from './getTextWidth';
import { getVisibleText } from './getVisibleText';

interface Props {
  shortText: string;
  longText: string;
}

export const useVisibleText = ({ shortText, longText }: Props) => {
  const textRef = useRef<RenderTextRefType>(null);
  const [isShowLongText, setShowLongText] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const textElement = textRef.current;
    if (!textElement) {
      return;
    }

    const longTextWidth = getTextWidth(textElement, longText);

    const resizeObserver = new ResizeObserver(
      debounce(() => setShowLongText(textElement.clientWidth >= longTextWidth)),
    );

    resizeObserver.observe(textElement);

    return () => {
      resizeObserver.unobserve(textElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visibleText = getVisibleText(isShowLongText, shortText, longText);

  return { visibleText, textRef };
};
