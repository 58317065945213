import type { FC } from 'react';
import React, { memo } from 'react';

import { Avatar, Badge } from '@sravni/react-design-system';
import type { CONTAINER_SIZES } from '@sravni/react-design-system/dist/types/constants/sizes';

import { Verification } from '@src/components/icons';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import {
  sendAdvertisingButtonClickEvent,
  sendOpenDetailsCardEvent,
  sendOrganizationOpenEvent,
} from '@src/helpers/analyticsEvents';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
  logo?: string;
  size?: typeof CONTAINER_SIZES[keyof typeof CONTAINER_SIZES];
  link: { href?: string; withAdvertising: boolean };
  analytics?: IAnalyticsItem;
  isPartner?: boolean;
  buttonEventLabel?: string;
}

const Wrapper: FC<IProps> = ({ className, analytics, link, buttonEventLabel, children }) => {
  if (!link.href) {
    return <div className={className}>{children}</div>;
  }

  if (link.withAdvertising) {
    const handleMonetizationClick = (evt: React.MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();

      if (analytics) {
        sendAdvertisingButtonClickEvent({
          ...analytics,
          advertising: analytics.advertising && {
            ...analytics.advertising,
            buttonEventAction: 'Платный клик|Логотип',
          },
        });
      }

      window.open(link.href, '_blank', 'noopener');
    };

    return (
      <a className={className} onClick={handleMonetizationClick}>
        {children}
      </a>
    );
  }

  const handleClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (analytics) {
      sendOrganizationOpenEvent(analytics);
    }

    if (buttonEventLabel) {
      sendOpenDetailsCardEvent(buttonEventLabel);
    }
  };

  return (
    <a className={className} href={link.href} onClick={handleClick}>
      {children}
    </a>
  );
};

export const Logo: FC<IProps> = memo(({ className, analytics, size = 44, logo, link, isPartner, buttonEventLabel }) => (
  <Wrapper className={className} analytics={analytics} link={link} buttonEventLabel={buttonEventLabel}>
    {isPartner ? (
      <Badge placement="bottom-end" text={<Verification />} className={styles.badge} shape="circle">
        <Avatar src={logo} size={size} className={styles.logo} />
      </Badge>
    ) : (
      <Avatar src={logo} size={size} className={styles.logo} />
    )}
  </Wrapper>
));
