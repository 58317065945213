const CustomTrash = ({ color }: { color: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4482 17.4997H6.54818C5.67568 17.4997 4.95068 16.8263 4.88568 15.9555L4.13818 5.83301H15.8332L15.1107 15.9513C15.0482 16.8238 14.3224 17.4997 13.4482 17.4997V17.4997Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 9.16699V14.167" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.33325 5.83301H16.6666" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.1666 5.83333L13.3224 3.58167C13.0783 2.93083 12.4566 2.5 11.7616 2.5H8.23825C7.54325 2.5 6.92158 2.93083 6.67742 3.58167L5.83325 5.83333"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.8583 9.16699L12.5 14.167L12.8583 9.16699Z" fill="#C11D1D" />
    <path
      d="M12.8583 9.16699L12.5 14.167"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.1416 9.16699L7.49994 14.167L7.1416 9.16699Z" fill="#C11D1D" />
    <path
      d="M7.1416 9.16699L7.49994 14.167"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CustomTrash;
