import type { ReactNode, RefObject } from 'react';

import { genericMemo } from 'shared/types';

import { useVisibleText } from '../lib';
import type { RenderTextRefType } from '../types';

import styles from './AdaptiveText.module.scss';

interface RenderTextProps {
  children: ReactNode;
  className: string;
  ref: RefObject<RenderTextRefType>;
}

interface Props {
  longText: string;
  shortText: string;

  renderText(props: RenderTextProps): ReactNode;
}

const AdaptiveTextComponent = ({ longText, shortText, renderText }: Props) => {
  const { visibleText, textRef } = useVisibleText({ shortText, longText });

  return (
    <>
      {renderText({
        children: visibleText,
        className: styles.text,
        ref: textRef,
      })}
    </>
  );
};

export const AdaptiveText = genericMemo(AdaptiveTextComponent);

AdaptiveText.displayName = 'AdaptiveText';
