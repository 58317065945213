import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { usePageContext } from '@src/config';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { sendAdvertisingButtonClickEvent, sendClickOnExtraInformationEvent } from '@src/helpers/analyticsEvents';
import { getOrganizationPageLink } from '@src/helpers/links';
import { usePixel } from '@src/hooks/usePixel';
import { productDetailsRoute, productPageRoute } from '@src/shared/constants/pageRoutes';
import { createLinkProps } from '@src/utils/routing';

import { getPixelClickLink, getPixelDisplayLink } from './utils';

interface IParams {
  offer: IClientMicrocreditListItem;
  position: number;
  shouldDontGetAffIdForTurbozaimPixelLink?: boolean;
}

export const useCardClick = ({ offer, position, shouldDontGetAffIdForTurbozaimPixelLink }: IParams) => {
  const {
    withCardProductLink,
    withMonetizationCardLogoClick,
    withCardClick,
    withMonetizationCardClick,
    source = 'search',
  } = usePageContext();
  const router = useRouter();

  const { name, organization, advertising, _id } = offer;
  const { monetization, advSub, productType, buttonColor } = advertising || {};

  const needMonetizationCardLogoClick = withMonetizationCardLogoClick && buttonColor !== 'white';

  const affSub4: AffSub4 = {
    organizationName: organization.name,
    productName: name,
    organizationId: organization._id,
    productId: _id,
  };

  const monetizationLink = usePixel(getPixelClickLink(monetization, shouldDontGetAffIdForTurbozaimPixelLink), {
    sub: advSub,
    type: productType,
    source: `${source}|position_${position}`,
    pixelType: PIXELS_TYPES.CLICK,
    affSub4,
    shouldGetAffId: !shouldDontGetAffIdForTurbozaimPixelLink,
  });

  const productLink = useMemo(() => {
    if (!withCardProductLink) {
      return undefined;
    }

    return createLinkProps('/item/', productPageRoute, {
      organizationAlias: offer.organization.alias,
      productAlias: offer.alias,
    });
  }, [withCardProductLink, offer.alias, offer.organization.alias]);

  const logoLink = useMemo(() => {
    if (needMonetizationCardLogoClick && monetizationLink) {
      return { href: monetizationLink, withAdvertising: true };
    }

    if (!organization.registrationDate) {
      return { href: '/zaimy/mfo/', withAdvertising: false };
    }

    return { href: getOrganizationPageLink(organization.alias), withAdvertising: false };
  }, [needMonetizationCardLogoClick, monetizationLink, organization.registrationDate, organization.alias]);

  const freeButtonLink = useMemo(() => getOrganizationPageLink(organization.alias), [organization.alias]);

  const cardLink = useMemo(() => {
    if (buttonColor !== 'white' && monetizationLink) {
      return { href: monetizationLink, withAdvertising: true };
    }

    return { href: getOrganizationPageLink(organization.alias), withAdvertising: false };
  }, [buttonColor, monetizationLink, organization.alias]);

  const handleMobileCardClick = useCallback(
    (evt) => {
      if (!withCardClick) {
        return;
      }

      if (withMonetizationCardClick && monetizationLink) {
        evt.stopPropagation();
        evt.preventDefault();
        sendAdvertisingButtonClickEvent({ ...offer, eventAction: 'Платный клик|Карточка' });
        window.open(monetizationLink, '_blank', 'noopener');

        return;
      }

      if (productLink) {
        router.push(productLink.href, productLink.as).then(() => {
          sendClickOnExtraInformationEvent(offer);
          window.scrollTo(0, 0);
          document.body.focus();
        });
      }
    },
    [withCardClick, withMonetizationCardClick, monetizationLink, productLink, offer, router],
  );

  const handleProductLinkClick = useCallback(() => {
    sendClickOnExtraInformationEvent(offer);
  }, [offer]);

  const detailsProductLink = createLinkProps('/details/', productDetailsRoute, {
    organizationAlias: offer.organization.alias,
    productAlias: offer.alias,
  });
  return {
    sourceLink: 'search',
    pixelDisplayLink: getPixelDisplayLink(offer, shouldDontGetAffIdForTurbozaimPixelLink),
    productLink,
    logoLink,
    freeButtonLink,
    cardLink,
    handleProductLinkClick,
    handleMobileCardClick,
    detailsProductLink,
  };
};
