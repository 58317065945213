import React from 'react';
import LazyLoad from 'react-lazyload';

import type { AffSub4 } from '@src/@types/microcredits';
import { PIXELS_TYPES } from '@src/constants/analytics';
import { usePixel } from '@src/hooks/usePixel';

import styles from './styles.module.scss';

interface IPixelProps {
  link?: string;
  sub?: string;
  source?: string;
  affSub4: AffSub4;
  shouldGetAffId?: boolean;
}

export const Pixel: React.FunctionComponent<IPixelProps> = ({ link, source, sub, affSub4, shouldGetAffId }) => {
  const src = usePixel(link, {
    source,
    sub,
    pixelType: PIXELS_TYPES.DISPLAY,
    affSub4,
    shouldGetAffId,
  });

  if (!link || !src) {
    return null;
  }

  return (
    <LazyLoad>
      <img className={styles.pixel} alt="" src={src} />
    </LazyLoad>
  );
};
