import type { IAdvertising } from '@src/@types/advertising';
import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import { TURBOZAIM_AB_TEST_PIXEL_CLICK, TURBOZAIM_AB_TEST_PIXEL_DISPLAY } from '@src/constants/abTest';

// todo: this file for turbozaim pixel AB testing. You should delete this after testing end.

export const getPixelDisplayLink = (offer: IClientMicrocreditListItem, isTurbozaimBVariant: boolean) => {
  if (isTurbozaimBVariant) {
    return TURBOZAIM_AB_TEST_PIXEL_DISPLAY;
  }

  return offer?.advertising?.monetization?.pixels?.display;
};

export const getPixelClickLink = (monetization: IAdvertising['monetization'], isTurbozaimBVariant: boolean) => {
  if (isTurbozaimBVariant) {
    return TURBOZAIM_AB_TEST_PIXEL_CLICK;
  }

  return monetization?.pixels.click;
};
