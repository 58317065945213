import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { Button, Icon, Typography } from '@sravni/react-design-system';
import { Tip } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import type { CommonCardProps } from '@src/@types/commonCardProps';
import type { AffSub4 } from '@src/@types/microcredits';
import { useMediaQuery } from '@src/components/item/hooks';
import Link from '@src/components/Link';
import { Logo } from '@src/components/Logotype';
import { Pixel } from '@src/components/Pixel';
import { ApprovalProbability } from '@src/components/ProductList/components/CardV2/components/ApprovalProbability';
import { usePageContext } from '@src/config';
import { Experiments } from '@src/constants/abTest';
import { ListKeys } from '@src/constants/microcredits';
import { getAmountString, humanizeTermRange } from '@src/helpers/common';
import { getCurrency } from '@src/helpers/currency';
import { useTestVariant } from '@src/hooks/useTestVariants';
import { useIsReviewer } from '@src/reducers/metadata';
import { useHasUtmLabel } from '@src/reducers/route';
import {
  isBezProtsentovPage,
  isLandingPage,
  isPartner2GisPage,
  isProverennyeZaymyPage,
  isSpecialPageWithoutQuery,
} from '@src/utils/routing';
import { IS_PROMO_FEATURE_ENABLED } from 'entities/Promo/lib/getPromoVariant';
import { isFullDealOrganization } from 'shared/config/fullDealOrganizationIds';
import { AdaptiveText } from 'shared/ui';

import { CardActionButton } from './CardActionButton';
import { ContentItem } from './ContentItem';
import styles from './styles.module.scss';
import { useCardClick } from './useCardClick';
import { getBadge, getTemporaryTerm } from './utils';

const TURBOZAIM_CARD_ID = '5e9c22189ad83a001b4ae935';

const MIN_WIDTH_PX = 0;
const MAX_WIDTH_PX = 460;
const PARTNER_2GIS_SOURCE_HO_PARAM = '8424';

/**
 * todo: memo не работает, стоит либо убрать его, либо подумать как оптимизировать.
 * Обновляется пропс offer.advertising.advSub при каждом ререндере
 */
const Card: FC<CommonCardProps> = memo(
  // eslint-disable-next-line complexity,max-statements
  ({ className, offer, position, afterCard, actionButton, detailsButtonProps, onContentClickCapture }) => {
    const { name, organization, approvalProbability, _id } = offer;
    const { listKey, isMainPage } = usePageContext();
    const { asPath } = useRouter();
    const isSpecial = isSpecialPageWithoutQuery(asPath);
    const isMobile = useIsMobile();
    const isReviewer = useIsReviewer();
    const hasUtmLabel = useHasUtmLabel();
    const isLanding = isLandingPage(asPath);
    const isPartner2Gis = isPartner2GisPage(asPath);

    // todo: this block of code for turbozaim pixel AB testing. You should delete this after testing end.
    const { isBVariant } = useTestVariant(Experiments.TURBOZAIM);
    const shouldDontGetAffIdForTurbozaimPixelLink =
      offer._id === TURBOZAIM_CARD_ID && (isSpecial || isMainPage) && isBVariant;
    const littleMode = useMediaQuery(MIN_WIDTH_PX, MAX_WIDTH_PX);

    const badge = useMemo(() => getBadge(offer, hasUtmLabel, styles), [offer, hasUtmLabel]);

    const currency = getCurrency(asPath);
    const amount = getAmountString(offer.details?.amount?.to || offer.details?.amount?.from, currency);
    const term = humanizeTermRange(offer.details?.term, { useAlternativeFormat: true, termWithUnit: false });

    const { sourceLink, pixelDisplayLink, logoLink, cardLink, freeButtonLink } = useCardClick({
      offer,
      position,
      shouldDontGetAffIdForTurbozaimPixelLink,
    });

    const affSub4: AffSub4 = {
      organizationName: organization.name,
      productName: name,
      organizationId: organization._id,
      productId: _id,
    };

    const shouldUseSpecialItem = isSpecial || isMainPage;
    const isTemporaryTerm = isReviewer && listKey === ListKeys.WL_PAGE;
    // const isAdvertisingInfoVisible = offer.advertising && !isSpecial && !isKZLanding;

    // для партнера 2GIS параметр source в ХО передаем определенное значение
    const sourceAffI = isPartner2Gis ? PARTNER_2GIS_SOURCE_HO_PARAM : `${sourceLink}|position_${position}`;
    const sourceAffC = isPartner2Gis ? PARTNER_2GIS_SOURCE_HO_PARAM : `${sourceLink}|position${position}`;
    const isFullDealCard = useMemo(
      () =>
        isFullDealOrganization(organization._id) &&
        !isProverennyeZaymyPage(asPath) &&
        !isBezProtsentovPage(asPath) &&
        IS_PROMO_FEATURE_ENABLED &&
        isMobile,
      [organization._id, asPath, isMobile],
    );
    return (
      <div className={cn(styles.cardWrapper, className)}>
        <div
          className={cn(styles.card, styles.link, className, { [styles.fullDealCard]: isFullDealCard })}
          data-qa="Card"
        >
          <div className={styles.content} onClickCapture={onContentClickCapture}>
            {isFullDealCard && (
              <AdaptiveText
                longText="Дарим 500₽ за займ"
                shortText="Дарим 500₽ за займ"
                renderText={(props) => (
                  <Typography.Text size={12} {...props} className={cn(styles.fullDealLabel, props.className)} />
                )}
              />
            )}
            <Link className={styles.link_content} link={cardLink} analytics={offer}>
              <div className={styles.header}>
                <div className={styles.text}>
                  <Typography.Heading className={styles.text} level={6} as="div">
                    {organization.name}
                  </Typography.Heading>
                </div>
              </div>
              {!!badge && !isSpecial && <div>{badge}</div>}
              <div className={styles.space} />
              <div className={styles.card_content}>
                {pixelDisplayLink && (
                  <Pixel
                    source={sourceAffI}
                    link={pixelDisplayLink}
                    affSub4={affSub4}
                    shouldGetAffId={!shouldDontGetAffIdForTurbozaimPixelLink}
                  />
                )}
                <div className={styles.content_row}>
                  <ContentItem className={styles.content_item} title="Сумма до" data={amount} />

                  {!isLanding && (
                    <ContentItem
                      className={cn(
                        styles.content_item,
                        shouldUseSpecialItem ? styles.content_item_special : undefined,
                      )}
                      classNameTitle={styles.content_item_title_special}
                      classNameData={styles.content_item_special}
                      title="Срок (дни)"
                      data={isTemporaryTerm ? getTemporaryTerm(organization._id, term) : term}
                      wrapDataByString={littleMode ? ' до ' : undefined}
                    />
                  )}

                  <ContentItem
                    data={<ApprovalProbability isNewDesignCard={false} category={approvalProbability?.category} />}
                    title="Одобрение"
                  />
                </div>
              </div>
            </Link>
            <Logo
              className={styles.header_logo}
              link={logoLink}
              analytics={offer}
              logo={organization.logotypes?.android || organization.logotypes?.square}
              isPartner={offer.isBankPartner || offer.advertising?.isPartner}
              size={isMobile ? 36 : 44}
            />
          </div>

          <div className={styles.actions}>
            {detailsButtonProps && (
              <Button
                variant="secondary"
                onClick={detailsButtonProps?.onClick}
                className={cn(styles.detailsButton, {
                  [styles.hiddenFromMobile]: !detailsButtonProps?.visibleOnMobile,
                })}
              >
                <Icon icon={<Tip />} />
              </Button>
            )}

            <CardActionButton
              className={styles.button}
              actionButton={actionButton}
              source={sourceAffC}
              offer={offer}
              affSub4={affSub4}
              shouldGetAffId={!shouldDontGetAffIdForTurbozaimPixelLink}
              freeButtonLink={freeButtonLink}
              visibleLabel={isMobile && detailsButtonProps?.visibleOnMobile ? 'Получить' : undefined}
            />
          </div>
          {/* todo(MFO-1414): временно (а может и нет) скрыли рекламу */}
          {/* {isAdvertisingInfoVisible && (
                          <Typography.Text className={styles.disclaimer}>
                              Реклама. {offer.organization.fullName || offer.organization.name}
                          </Typography.Text>
                      )} */}
        </div>

        {afterCard}
      </div>
    );
  },
);

Card.displayName = 'Card';

export default Card;
