import type { FC, ReactElement, ReactNode } from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { FreeButton } from '@src/components/FreeButton';

interface Props {
  offer: IClientMicrocreditListItem;
  actionButton: ReactNode;
  freeButtonLink: string;
  affSub4: AffSub4;
  source: string;
  shouldGetAffId: boolean;
  className: string;
  visibleLabel?: string;
}

export const CardActionButton: FC<Props> = ({
  offer,
  freeButtonLink,
  actionButton,
  className,
  ...advertisingBtnProps
}) => {
  if (actionButton) {
    return actionButton as ReactElement;
  }

  const { advertising } = offer;

  if (advertising) {
    return (
      <AdvertisingButton advSub={advertising.advSub} analytics={offer} className={className} {...advertisingBtnProps} />
    );
  }

  return <FreeButton analytics={offer} link={freeButtonLink} className={className} />;
};
