import { UNBREAKABLE_SEPARATOR } from '@src/constants/unbreakableSeparator';

export const getVisibleText = (isShowLongText: undefined | boolean, shortText: string, longText: string) => {
  let visibleText = UNBREAKABLE_SEPARATOR;

  if (isShowLongText === true) {
    visibleText = longText;
  }

  if (isShowLongText === false) {
    visibleText = shortText;
  }

  return visibleText;
};
