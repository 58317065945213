import type { RenderTextRefType } from '../types';

export function getTextWidth(element: RenderTextRefType, text: string) {
  const copy = element.cloneNode(true) as RenderTextRefType;

  copy.style.cssText = `visibility: hidden; width: fit-content;`;
  copy.textContent = text;

  document.body.appendChild(copy);
  const width = copy.offsetWidth;
  document.body.removeChild(copy);

  return width;
}
