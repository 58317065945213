import type { FC, HTMLAttributes, ReactNode } from 'react';
import React from 'react';

import { Space } from '@sravni/react-design-system';
import type { SpaceProps } from '@sravni/react-design-system/dist/types/components/Space';

import { typograf } from '@src/helpers/typograf';

interface Text extends HTMLAttributes<HTMLParagraphElement> {
  text: string;
}

interface Props extends SpaceProps {
  texts: Array<Text | ReactNode>;
}

export const TextList: FC<Props> = ({ texts, ...containerProps }) => (
  <Space direction="vertical" {...containerProps}>
    {texts.map((text) =>
      typeof text === 'object' && 'text' in text ? (
        <p key={text.text} className={text.className}>
          {typograf.execute(text.text)}
        </p>
      ) : (
        text
      ),
    )}
  </Space>
);
