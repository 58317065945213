const FULL_DEAL_ORGANIZATION_IDS = [
  // prod
  '6734b6ec375990002a1a1dab',
  // dev
  '6734bd79c81ca8002bccbe4e',
  '6745922fc7a5e60029c5b780',
  '6734b8eba871da0027300346',
];

export const isFullDealOrganization = (id: string) => FULL_DEAL_ORGANIZATION_IDS.includes(id);
