import { useCallback, useEffect, useState } from 'react';

export const useMediaQuery = (minWidth: number, maxWidth: number) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const media = window.matchMedia(`(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`);
      media.addListener(updateTarget);

      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeListener(updateTarget);
    }
  }, [minWidth, maxWidth, updateTarget]);

  return targetReached;
};
