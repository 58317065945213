const PHONE_NUMBER_LENGTH = 12;

export const validateKazakhstanPhoneNumber = (phoneNumber: number | string) => {
  const isNumber = typeof phoneNumber === 'number';
  let phoneNumberString = String(phoneNumber).trim();

  if (isNumber || phoneNumberString[0] !== '+') {
    phoneNumberString = `+${phoneNumberString}`;
  }

  const isValidPhoneNumberStart = phoneNumberString.startsWith('+76') || phoneNumberString.startsWith('+77');
  const isValidPhoneNumberLength = phoneNumberString.length === PHONE_NUMBER_LENGTH;

  return isValidPhoneNumberStart && isValidPhoneNumberLength;
};
