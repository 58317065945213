const PHONE_NUMBER_LENGTH = 12;

export const validateRussianPhoneNumber = (phoneNumber: number | string) => {
  const isNumber = typeof phoneNumber === 'number';
  let phoneNumberString = String(phoneNumber).trim();

  if (isNumber || phoneNumberString[0] !== '+') {
    phoneNumberString = `+${phoneNumberString}`;
  }

  const isValidPhoneNumberStart = phoneNumberString.startsWith('+79');
  const isValidPhoneNumberLength = phoneNumberString.length === PHONE_NUMBER_LENGTH;

  return isValidPhoneNumberStart && isValidPhoneNumberLength;
};
