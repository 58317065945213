import cn from 'classnames';
import { useRouter } from 'next/router';
import type { MouseEventHandler } from 'react';
import React, { memo, useCallback } from 'react';

import { Button } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import styles from '@src/components/AdvertisingButton/styles.module.scss';
import type { IAnalyticsItem } from '@src/helpers/analyticsEvents';
import { sendClickOnExtraInformationEvent } from '@src/helpers/analyticsEvents';

interface IProps extends ButtonProps {
  className?: string;
  analytics: IAnalyticsItem;
  link?: string;
  buttonEventAction?: string;
  buttonEventLabel?: string;
  onClick?: () => void;
}

export const FreeButton: React.FunctionComponent<IProps> = memo(
  ({ className, analytics, link, onClick, ...buttonProps }) => {
    const router = useRouter();

    const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
      (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        onClick?.();

        if (link) {
          sendClickOnExtraInformationEvent(analytics);
          router.push(link);
        }
      },
      [analytics, link, router, onClick],
    );

    // @todo: хочу посмотреть используется ли когда нибудь эта кнопка, думаю что нет и ее можно удалить
    // eslint-disable-next-line no-console
    console.log('render FreeButtonFreeButtonFreeButton');

    return (
      <a className={cn(styles.link, className)} onClick={handleClick}>
        <Button className={styles.button} color="gray" variant="secondary" {...buttonProps}>
          Подробнее
        </Button>
      </a>
    );
  },
);
